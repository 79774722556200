* {
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

#theme {
  display: none;
}

#theme:checked~* {
  filter: invert(1);
}

.App {
  width: 100%;
  min-height: 100vh;
  background-color: white;
}

.main {
  /* max-width: 1000px; */
  width: 100%;
  min-height: 100vh;
  margin: auto;
  background-color: white
}

img {
  width: 300px;
  height: 300px;
  aspect-ratio: 3/2;
  object-fit: contain;
}

/* -------- AUTH ---------- */
@import url("./auth.css");

/* -------- LANDING ---------- */
@import url("./landing.css");

/* -------- LOADING ---------- */
@import url("./loading.css");

/* -------- Header ---------- */
@import url("./header.css");

/* -------- Avatar ---------- */
@import url("./avatar.css");

/* -------- Notify ---------- */
@import url("./notify.css");

/* -------- Profile ---------- */
@import url("./profile.css");

/* -------- Home ---------- */
@import url("./home.css");

/* -------- Side Bar ---------- */
@import url("./side_bar.css");

/* -------- Status Modal ---------- */
@import url("./status_modal.css");

/* -------- Comments ---------- */
@import url("./comments.css");

/* -------- Post Thumb ---------- */
@import url("./post_thumb.css");

/* -------- Message---------- */
@import url("./message.css");

/* -------- Icons---------- */
@import url("./icons.css");

/* -------- Call Modal---------- */
@import url("./call_modal.css");

/* -------- Search---------- */
@import url("./search.css");

/* -------- Publication Modal---------- */
@import url("./publication_modal.css");

/* -------- Category---------- */
@import url("./category.css");

/* -------- Admin ----------- */
@import url("./admin.css");

/* --------- Responsive ------ */
@media(max-width: 768px) {

  .main {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background-color: white;
  }

  .header .menu {
    width: 100%;
    height: 40px;
  }

  .header .menu .navbar-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header .search_form {
    width: 100%;
  }

  .header .logo {
    margin: auto;
  }

  .header .logo h1 {
    font-size: 1.5rem;
  }

  .right_side_bar,
  .left_side_bar {
    display: none;
  }

  .message {
    height: calc(100vh - 100px);
    position: sticky;
  }

  .left_mess {
    display: none;
  }

  .status {
    padding: 20px 10px;
  }

  .profile_tab button {
    font-size: 15px;
  }
}

@media screen and (max-width: 375px) {

  .main {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background-color: white;
  }

  .header .menu {
    width: 100%;
    height: 40px;
  }

  .header .menu .navbar-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .header .search_form {
    width: 100%;
  }

  .header .logo {
    margin: auto;
  }

  .header .logo h1 {
    font-size: 1.5rem;
  }

  .container_home {
    width: 100%;
    height: 100%;
    margin: auto;
    overflow: hidden;
    background-color: white;
  }

  .right_side_bar,
  .left_side_bar {
    display: none;
  }

  .message {
    height: calc(100vh - 100px);
  }

  .left_mess {
    display: none;
  }

  .status {
    padding: 20px 10px;
  }

  .profile_tab button {
    font-size: 15px;
  }
}

.mode {
  width: 100%;
  height: 100vh;
  overflow: hidden;
}