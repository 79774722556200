/* --------- Container ------- */
.container_home {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: row;
    margin: 0;
    position: relative;
}

/* --------- Home ------------ */
.home {
    position: relative;
}

/* --------- Status ---------- */
.status {
    background: white;
    box-shadow: 0 0 5px white;
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px solid #eee;
}

.status .statusBtn {
    border: none;
    outline: none;
    border-radius: 30px;
    color: black;
    padding: 0 10px;
    text-align: left;
    margin: 0 5px;
}

.status .statusBtn:hover {
    background: #eee;
}

/* ------------Posts ------ */
.posts {
    background: white;
    box-shadow: 0 0 5px white;
    padding: 15px 25px;
    border-radius: 5px;
    border: 1px solid #eee;
    margin-bottom: 10px;
}

.posts .card {
    box-shadow: 0 0 4px white;
}

/* --------- Post Card Header --------- */
.posts .card_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    padding: 15px 25px;
}

.posts .card_header .card_name {
    display: flex;
    justify-content: center;
    align-items: left;
    padding: 0 5px;
    flex-direction: column;
    margin-left: 5px;
    transform: translateY(3px);
}

.posts .card_header .card_name h6 a {
    margin: 0;
    padding-bottom: 5px;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
}

.posts .card_header .dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 5px;
    flex-direction: column;
    margin-left: 5px;
}

.posts .card_header span:hover {
    cursor: pointer;
    background-color: #eee;
    border-radius: 50%;
}

.posts .card_header .dropdown-menu {
    position: absolute;
    right: 0;
    left: inherit;
}

.posts .card_header .dropdown-item {
    display: flex;
    margin: 5px 0;
}

/* ---------- Post Card Body ---------- */
.posts .card_body-content {
    padding: 0 25px;
    margin-top: -10px;
    margin-bottom: 10px;
    font-size: 20px;
    line-height: 1.5;
}

.posts .card_body-content .readMore {
    color: tomato;
    cursor: pointer;
}

/* ---------- Post Card Footer ---------- */
.posts .card_footer {
    display: flex;
    flex-direction: column-reverse;
    background-color: white;
}


.posts .card_number {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.posts .card_icon_menu {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 15px;
    margin: 0 10px 0 10px;
    border-top: 1px solid #eee;
    border-bottom: 1px solid #eee;
    cursor: pointer;
}

.posts .card_icon_menu i {
    font-size: 28px;
    margin: 10px;
    padding: auto;
}