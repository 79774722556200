.message {
    position: fixed;
    width: 100%;
    height: calc(100vh - 70px);
    border-radius: 3px;
    background: #fbfbfb;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* ---------------- Left side ------------------ */
.message_header input {
    flex: 1;
    height: 100%;
    border: none;
    outline: none;
    background: #f8f8f8;
    padding: 0 15px;
}

.message_header input:focus-visible {
    color: #0048AA;
    border-bottom: #0048AA 1px solid;
}

.message_chat_list {
    width: 100%;
    height: calc(100% - 60px);
    overflow-y: auto;
    cursor: pointer;
    padding: 5px;
}

/* .message_chat_list .message_user .undefined {
    width: 100%;
    height: auto;
    padding: 5px;
    background-color: white;
    margin: auto;
}
*/

.message_chat_list .message_user .undefined:hover {
    background-color: #f3f3f3;
    border-radius: 15px;
    padding: 5px;
}

.message_chat_list .message_user.active {
    background: #d0fff7;
    border-radius: 15px;
}

.message_chat_list .message_user.active:hover {
    background: #d0fff7;
    border-radius: 15px;
}

.message_chat_list .message_user a {
    color: #555;
}

.message_chat_list .message_user a:hover {
    text-decoration: none;
}

.message_chat_list .message_user .fa-circle {
    font-size: 8px;
    color: #aaa;
}

.message_chat_list .message_user .fa-circle.active {
    color: green;
}

/* ----------------Right side ------------------ */
.message_header {
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f8f8f8;
    border-radius: 10px;
}

.message_header .undefined {
    border-radius: 10px;
    background: transparent;
    border-bottom: #777;
    box-sizing: border-box;
}

.message_header .undefined .message_header_menu {
    display: flex;
    align-items: center;
    color: blue;
    background: transparent;
    border-radius: 10px;
    font-size: 20px;
}

.message_header .undefined .message_header_menu .fas {
    font-size: 20px;
    margin: 0 5px;
    padding: 10px;
    cursor: pointer;
    box-sizing: border-box;
    background: transparent;
    border-radius: 50%;
}

.message_header .undefined .message_header_menu .fas:hover {
    background-color: #aaa;
    border-radius: 50%;
}

.message .col-md-8 {
    border-radius: 10px;
    background-color: transparent;
    border-bottom: 1px solid #eee;
}

/* -------- Chat Input----- */
.chat_input {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 5px 0 0;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
    height: 40px;
    background-color: white;
    border: #eee 1px solid;
    border-radius: 20px;
}

.chat_input input {
    /* border: none; */
    outline: none;
    position: relative;
    background: white;
    border: 1px solid white;
    outline: none;
    padding-left: 5px;
    flex: 1;
    overflow: auto;
    color: black;
}

.chat_input button {
    border: none;
    outline: none;
    background: white;
}

.chat_input .file_upload {
    position: relative;
    overflow: hidden;
    margin: 0 10px;
}

.chat_input .file_upload #file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.show_media {
    width: 100%;
    height: 70px;
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(70px, 1fr));
    place-items: center;
    grid-gap: 10px;
    background: #f3f3f3;
    border-radius: 5px;
    padding: 0 5px;
}

.show_media #file_media {
    position: relative;
    width: 100%;
    height: 100%;
    max-width: 70px;
    max-height: 70px;
}

.show_media #file_media img,
.show_media #file_media video {
    width: 100%;
    height: 100%;
    display: block;
    object-fit: contain;
}

.show_media #file_media span {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 4;
    background: white;
    border: 1px solid tomato;
    padding: 3px 7px;
    color: tomato;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bold;
    cursor: pointer;
}

/* -------- Chat Container ----- */
.chat_container {
    width: 100%;
    height: calc(100% - 110px);
    overflow-y: auto;
    padding: 0 10px;
}

.chat_display {
    width: 100%;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.chat_display .chat_time {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.chat_row {
    display: grid;
    grid-template-columns: 70%;
}

.you_message {
    justify-content: end;
    justify-items: end;
}

.other_message {
    justify-content: start;
    justify-items: start;
}

.chat_text {
    padding: 9px 15px;
    margin-bottom: 5px;
}

.you_message .chat_text {
    background: #0048AA;
    color: white;
    border-radius: 15px;
}

.other_message .chat_text {
    background: whitesmoke;
    color: #111;
    border-radius: 15px;
}

.chat_title {
    margin-bottom: 3px;
}

.you_content {
    position: relative;
}

.you_content .fa-trash {
    position: absolute;
    top: 50%;
    left: -15px;
    transform: translateY(-50%);
    cursor: pointer;
    font-size: 15px;
    opacity: 0;
}

.you_content:hover .fa-trash {
    opacity: 1;
}

.chat_input .dropdown-menu {
    transform: translate3d(-110px, -200px, 0px) !important;
}

/* --------------------- Mess Info ------------------- */
.info_mess {
    width: 100%;
    height: calc(100vh - 70px);
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    background: #f8f8f8;
    border-radius: 10px;
}