/* ---------------- Form -------------- */
.pub_content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0008;
    z-index: 9;
    overflow: auto;
}

.pub_content form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    height: 100vh;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
    z-index: 10;
    max-height: 80vh;
    overflow-y: auto;
}

.pub_content .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}

.pub_content .mb-3 .react-datepicker__input-container {
    width: 100%;
}

/* ----------------Table ---------------- */
#table {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
}

#table td,
#table th {
    border: 1px solid #ddd;
    padding: 8px;
    width: 30px;
}

#table tr:nth-child(even) {
    background-color: #f2f2f2;
}

#table tr:hover {
    background-color: #ddd;
}

#table th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: #04AA6D;
    color: white;
}

#table td button {
    font-size: 14px;
}

.publication {
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    align-items: start;
}

.publication a {
    text-decoration: none;
    color: #000;

}

.publication .publication_info {
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.publication .publication_detail #publication_detail_table {
    width: 100%;
    border-collapse: collapse;
    font-size: 18px;
}

.publication .publication_detail #publication_detail_table_td {
    width: 20%;
    text-align: right;
    padding: 0px 10px 0px 0px;
}