.status_modal {
    position: fixed;
    top: 0;
    left: 0;
    background: #00000030;
    z-index: 4;
    width: 100%;
    height: 100vh;
    overflow: auto;
}

.status_modal form {
    max-width: 1000px;
    width: 100%;
    background: white;
    margin: 30px auto;
    padding: 20px;
    border-radius: 5px;
}

.status_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid white;
    transform: translateY(-13px);
}

.status_header span {
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
    transform: translateY(-5px);
}

.status_body textarea {
    width: 100%;
    min-height: 150px;
    border: none;
    outline: none;
    resize: none;
}

.status_body .input_images {
    display: flex;
    justify-content: center;
    margin: 10px 0;
}

.status_body .input_images i {
    font-size: 2rem;
    cursor: pointer;
}

.status_body .input_images .file_upload {
    overflow: hidden;
    margin: 0 10px;
    position: relative;
}

.status_body .input_images .file_upload #file {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
}

.status_body .show_images {
    max-height: 270px;
    width: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));
    place-items: center;
    padding: 10px 0;
    grid-gap: 10px;
}

.status_body .show_images #file_img {
    position: relative;
    width: 100%;
    height: 100%;
}

.status_body .show_images #file_img img,
.status_body .show_images #file_img video {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
    max-height: 100px;
}

.status_body .show_images #file_img span {
    position: absolute;
    top: -1px;
    right: -2px;
    z-index: 4;
    background: white;
    color: tomato;
    padding: 3px 7px;
    border: 1px solid tomato;
    border-radius: 50%;
    font-size: 10px;
    font-weight: bolder;
    cursor: pointer;
}

.status_body .stream span {
    position: absolute;
    top: -10px;
    right: 5px;
    color: tomato;
    font-size: 2rem;
    font-weight: 900;
    cursor: pointer;
}

.status_body .dropdown-menu {
    transform: translate3d(-153px, -190px, 0px) !important;
}

.status_body .create_post {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.status_body .create_post .create_post_form {
    margin: 0;
    padding: 0;
}

.status_body .create_post .create_post_form .rc-md-editor {
    height: 300px;
}

.status_body .create_post .title h5 {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.status_body .create_post .create_post_item {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 5px;
    overflow-y: hidden;
}

.status_body .create_post .create_post_item span {
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 5px;
}

.status_body .create_post .create_post_item .react-datepicker-wrapper {
    width: 100%;
}

.status_body .create_post .create_post_item input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}

.status_body .create_post .create_post_item .select {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
}