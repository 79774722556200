.notify {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    color: red;
    padding: 0 10px;
}

.notify h3 {
    font-size: 24px;
    margin-bottom: 5px;
}

.notify_content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    /* height: 500px; */
    padding: 0;
    text-decoration: none;
}

.notify_content .notify_content_text {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2.5px;
    width: 100%;
    flex-direction: column;
}

.notify_content .notify_content2 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    padding: 0 5px;
    border: 1px solid white;
}

.notify_content .notify_content2 .notify_content1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: row;
    width: 100%;
}

.notify_content .notify_content2 .notify_content1 .notify_content_text {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 5px;
    display: flex;
    align-items: flex-start;
    /* justify-content: flex-end; */
    padding-left: 5px;

}

.notify_content .notify_content2 .notify_content1 .notify_content_text_link {
    color: black;
    max-width: 200px;
    max-height: calc(100vh - 200px);
    overflow: hidden;
}

.notify_content .notify_content2 .notify_content1 .notify_content_text_link .notify_content_text_bw {
    color: black;
    max-width: 200px;
    max-height: calc(100vh - 200px);
    overflow: hidden;
    text-decoration: none;
}

.notify_content .notify_content2 .notify_content1 .notify_content_text_time {
    color: #007bff;
}