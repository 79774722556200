.user-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.user-table th,
.user-table td {
    padding: 10px;
    border: 1px solid #ccc;
}

.user-table th {
    background-color: #f0f0f0;
    cursor: pointer;
}

.user-table th.sortable:hover {
    background-color: #e0e0e0;
}

.user-table th.sortable:active {
    background-color: #d0d0d0;
}

.user-table th.sortable:hover::after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 4px 4px 0 4px;
    border-color: #333 transparent transparent transparent;
}

.user-table th.sortable:active::after {
    content: "";
    display: inline-block;
    margin-left: 5px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 4px 4px 4px;
    border-color: transparent transparent #333 transparent;
}

.modal_post_user {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #0008;
    z-index: 9;
    overflow: auto;
}

.modal_post_user form {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
    max-width: 500px;
    height: 100vh;
    background: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px #000;
    z-index: 10;
    max-height: 80vh;
    overflow-y: auto;
}

.modal_post_user .btn-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
}