.search_page {
    position: absolute;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 0;
}

.search_page .search_header {
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: white;
    padding-bottom: 20px;
}

.search_page .search_form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 50%;
    border-bottom: 3px solid #d1d1d1;
    position: relative;
    height: auto;
    background: white;
}

.search_page .search_form #search {
    background: white;
    border: none;
    min-width: 250px;
    width: 100%;
    height: 30px;
    outline: none;
    text-indent: 5px;
}

.search_page .search_form:focus-within {
    border-bottom: #164cee 3px solid;
}

.search_page .search_form input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    outline: none;
    text-indent: 5px;
}

.search_page .search_form input::placeholder {
    color: black;
    font-size: 14px;
    text-indent: 5px;
}

.search_page .search_form .search_close:hover {
    position: relative;
    font-size: 14px;
    pointer-events: none;
    color: black;
}

.search_page .search_form .loading {
    position: absolute;
    top: 50%;
    right: 5px;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}

.search_page .search_form .search_input {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.search_page .search_results {
    width: 80%;
    height: calc(100vh - 100px);
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 10px 0;
}

.search_page h1,
.search_page .search_results h1 {
    font-size: 32px;
    color: #000;
    border-top: #000;
    margin: auto;
    padding: 10px;
}

.search_page .search_results .search_tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    width: 100%;
    border-top: 1px solid rgb(239, 239, 239);
    border-bottom: 1px solid rgb(239, 239, 239);
}

.search_page .search_results .search_tab button {
    width: 100px;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: white;
    outline: none;
    border: none;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 1.2rem;
    padding: 5px 20px;
    margin: 0 25px;
    opacity: 0.5;
}

.search_page .search_results .search_tab button.active {
    border-bottom: 3px solid black;
    opacity: 0.5;
}

.search_page .search_results .abc a {
    text-decoration: none;
    border-bottom: 1px solid #000;
    color: #000;
    font-size: 20px;
    padding: 10px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    transition: all 0.3s ease-in-out;
}

.search_page .search_results .abc {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
}